@tailwind base;
@tailwind components;
@tailwind utilities;

.header-title::after {
  content: '';
  @apply bg-green-900;
  width: 80px;
  height: 5px;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}
.btn_li{
  @apply text-green-600 hover:text-red-800 focus:text-red-800  cursor-pointer;
}

.btn_li:active{
  @apply focus:text-red-800;
}
.hideContent{
  @apply hidden;
}
.showContent{
  @apply block ;
}

.orderedlist{
  @apply list-disc;
}