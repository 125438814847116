@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  @apply text-green-600 hover:text-red-800 focus:text-red-800 cursor-pointer;
}

.btn_li:active {
  @apply focus:text-red-800;
}

.hideContent {
  @apply hidden;
}

.showContent {
  @apply block;
}

.image_container{
  @apply w-80 rounded overflow-hidden shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 relative;
}