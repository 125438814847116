/* src/Gallery.css */
.gallery {
  padding: 20px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
}

.gallery-item {
  cursor: pointer;
  position: relative;
}

.gallery-image {
  width: 300%;
  height: auto;
}

.gallery-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
}

.media-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.media-content {
  max-width: 80%;
  max-height: 80%;
}

.media-content img,
.media-content video {
  width: 100%;
  height: auto;
}