@tailwind base;
@tailwind components;
@tailwind utilities;

/* CONTACT STYLES */

.btn_content{
    @apply border-b border-black hover:text-green-900 hover:bg-green-100 focus:text-red-700 focus:bg-green-100 text-black font-bold text-lg p-2 w-full flex justify-between items-center active:text-red-700 active:bg-green-100 ;
}
.content_btn{
    @apply border-b border-black hover:text-green-900 hover:bg-green-100 focus:text-red-700 focus:bg-green-100 text-black font-bold text-lg p-2 w-full flex justify-between items-center active:text-red-700 active:bg-green-100;
}
.content{
    @apply hidden;
}
.show_content {
    @apply block;
}



/* END */