@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');



/* NAVBAR DEC */

.header .navbar ul {
    list-style: none;
    font-family: 'Open Sans', sans-serif;
    @apply uppercase;
}

.header .navbar ul li {
    position: relative;
    float: left;
    @apply font-[''] text-sm text-gray-300 font-bold;
}

.header .navbar ul li a {
    font-size: 12px;
    padding: 10px;
    color: white;
    display: block;
}

.header .navbar ul li ul li a {
    padding: 6px;
}

.header .navbar ul li a:hover {
    @apply text-green-600;
}

.header .navbar ul li ul {
    position: absolute;
    top:30px;
    left: 0;
    width: 200px;
    @apply bg-green-900;
    display: none;
    padding:20px 0px 3px 5px;
}

.header .navbar ul li ul li {
    width: 100%;
    
}

.header .navbar ul li ul li ul {
    left: 200px;
    top: 0;
    padding: 0px 0px 0px 5px;
}

.header .navbar ul li:focus-within>ul,
.header .navbar ul li:hover>ul {
    display: initial;
}


/* SCREEN REPONSIVE small */
.hide_cont{
    @apply hidden;
}
.show_cont{
    @apply block pl-2 space-y-2 divide-y flex-col items-center pt-3;
}

.nav-menu{
    @apply bg-green-900 min-h-screen w-[300px] ;
}
.nav-menu .nav-ul .nav-li{
    @apply focus:bg-white focus:p-2 focus:rounded-md uppercase;
}
.nav-menu .nav-ul{
    @apply flex flex-col px-4 space-y-3;
}
.link_flex {
    @apply flex justify-between items-center;
}

/* END OF NAVBAR DEC */

